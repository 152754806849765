import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Text } from '../components/text';
import Spinner from 'react-bootstrap/Spinner';
import '../components/css/spinner.css';
import * as formStyles from './css/form.module.css';

const Form = () => {
  const [data, setData] = useState({
    name: {
      value: '',
      error: false,
    },
    email: {
      value: '',
      error: false,
    },
    message: {
      value: '',
      error: false,
    },
  });

  const [loading, setLoading] = useState(false);

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        value: e.target.value,
        error: e.target.value.length === 0 ? true : false,
      },
    });
  };

  useEffect(() => {}, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      data.name.value.length === 0 ||
      data.email.value.length === 0 ||
      data.message.value.length === 0
    ) {
      for (const prop in data) {
        data[prop].error = data[prop].value.length === 0 ? true : false;
      }
      setData({ ...data });
      return;
    }

    setLoading(true);
    const formData = {
      name: data.name.value,
      email: data.email.value,
      message: data.message.value,
    };

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        ...formData,
      }),
    })
      .then(() =>
        navigate('/success/', {
          state: { success: true },
        })
      )
      .catch((error) => alert(error));
  };

  return (
    <form
      className={formStyles.formContainer}
      method='post'
      data-netlify='true'
      netlify-honeypot='bot-field'
      name='contact'
      onSubmit={handleSubmit}
    >
      <input type='hidden' name='form-name' value='contact' />
      <label className={formStyles.hidden}>
        Don’t fill this out if you’re human:
        <input name='bot-field' />
      </label>
      <label className={formStyles.inputSmall}>
        <h5>
          <Text tid='name' />
        </h5>
        <input
          type='text'
          name='name'
          id='name'
          value={data.name.value}
          onChange={handleChange}
        />
        {data.name.error && (
          <span className={formStyles.errorMsg}>
            <Text tid='error' />
          </span>
        )}
      </label>
      <label className={formStyles.inputSmall}>
        <h5>
          <Text tid='email' />
        </h5>
        <input
          type='email'
          name='email'
          id='email'
          value={data.email.value}
          onChange={handleChange}
        />
        {data.email.error && (
          <span className={formStyles.errorMsg}>
            <Text tid='error' />
          </span>
        )}
      </label>
      <label>
        <h5>
          <Text tid='message' />
        </h5>
        <textarea
          name='message'
          id='message'
          rows='4'
          value={data.message.value}
          onChange={handleChange}
        />
        {data.message.error && (
          <span className={formStyles.errorMsg}>
            <Text tid='error' />
          </span>
        )}
      </label>
      {!loading ? (
        <button className={formStyles.formButton} type='submit'>
          <Text tid='submit' aria-label='send' />
        </button>
      ) : (
        <button className={formStyles.formButton} type='submit'>
          <Text tid='sending' aria-label='sending' />
          <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
            className={formStyles.spinnerMargin}
          />
          <span className={formStyles.srOnly}>Loading</span>
        </button>
      )}
    </form>
  );
};

export default Form;
