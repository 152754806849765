import React from 'react';
import Form from '../components/form';
import * as layoutStyles from '../components/css/layout.module.css';
import { motion } from 'framer-motion';

const Contact = (props) => {
  return (
    <motion.div
      key={props.location.pathname}
      variants={variants}
      initial='initial'
      animate='enter'
      exit='exit'
      className={`${layoutStyles.textContainer} ${layoutStyles.subContainer}`}
    >
      <Form />
    </motion.div>
  );
};

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: 0.3,
  },
  exit: {
    opacity: 0,
    x: 600,
    transition: { duration: 0.3 },
  },
};

export default Contact;
